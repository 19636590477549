import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [{//主页
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index.vue')
    },]
}, {
    path: '/about',//关于我们
    component: Layout,
    redirect: '/Gsituation',
    children: [{
        path: 'introduction',//企业概况
        name: 'Gsituation',
        component: () => import('@/views/aboutUs/Gsituation.vue')
    }, {
        path: 'culture',//企业文化
        name: 'Culture',
        component: () => import('@/views/aboutUs/Culture.vue')
    }, {
        path: 'qualification',//资质荣誉
        name: 'Approve',
        component: () => import('@/views/aboutUs/Approve.vue')
    }, {
        path: 'commitment',//我们的承诺
        name: 'Promise',
        component: () => import('@/views/aboutUs/Promise.vue')
    }, {
        path: 'Declaration',//我们的承诺点进去的声明
        name: 'Declaration',
        component: () => import('@/views/aboutUs/Declaration.vue')
    }, {
        path: 'inspection',//检验检测
        name: 'inspection',
        component: () => import('@/views/mainService/inspection.vue')
    }, {
        path: 'accreditation',//认证认可
        name: 'certification',
        component: () => import('@/views/mainService/certification.vue')
    }, {
        path: 'consulting',//咨询服务
        name: 'Consult',
        component: () => import('@/views/mainService/Consult.vue')
    }, {
        path: 'attestation',//认证
        name: 'attestation',
        component: () => import('@/views/mainService/attestation.vue')
    }]
}, {
    path: '/mainService',//核心服务
    component: Layout,
    redirect: '/inspection',
    children: []
}, {
    path: '/media',//资讯中心
    component: Layout,
    redirect: '/media',
    children: [{
        path: 'media',
        name: 'informationCenter',
        component: () => import('@/views/informationCenter/index.vue')
    }, {
        path: 'articles',//企业新闻
        name: 'articles',
        component: () => import('@/views/informationCenter/articles.vue')
    }, {
        path: 'article',//行业动态
        name: 'article',
        component: () => import('@/views/informationCenter/article/article.vue')
    }
    ]
}, {
    path: '/contact',//联系我们
    component: Layout,
    redirect: '/contacts',
    children: [{
        path: 'careers',//招纳贤士
        name: 'careers',
        component: () => import('@/views/contactUs/talent.vue')
    }, {
        path: 'clients',//客户中心
        name: 'clients',
        component: () => import('@/views/contactUs/contacts.vue')
    }
    ]
}, {
    path: '/others',//其他声明
    component: Layout,
    redirect: '/',
    children: [{
        path: '/',//其他声明
        name: 'others',
        component: () => import('@/views/other/index.vue')
    }
    ]
}, {
    path: '/specialist',//其他声明
    component: Layout,
    redirect: '/',
    children: [{
        path: '/',//其他声明
        name: 'specialist',
        component: () => import('@/views/specialist/specialist.vue')
    }
    ]
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
