<!--咨询合作-->
<template>
    <!--    选择我们 s-->
    <div class="jc-choose">
        <div class="mtitle"></div>
        <div class="jc-choose-c">
            <div class="jc-choose-l">
                <div @click="show=true">
                    <img src="@/assets/common/zx.png" alt="">
                    <span>服务咨询</span>
                </div>
                <div @click="showLc=true">
                    <img src="@/assets/common/hz.png" alt="">
                    <span>合作流程</span>
                </div>
            </div>
            <div class="jc-choose-r">
                <img alt="" src="@/assets/common/hzzxbg.png"/>
            </div>

        </div>

        <div class="contact_box" v-if="showLc">
            <div class="mask" @click="showLc=false"></div>
            <div class="liuchengbox" @click="showLc=false">
                <img src="@/assets/common/liuchneg2.png" alt="" style="width: 1063px;">
            </div>
        </div>

        <!--    选择我们 s-->
        <div class="contact_box" v-if="show">
            <div class="mask" @click="show=false"></div>
            <div class="contact_content">
                <div class="contact_content_t">
                    <div style="margin-right: 100px;" :class="tabIndex==0?'select':''" @click="tabIndex=0">联系我们</div>
                    <div :class="tabIndex==1?'select':''" @click="tabIndex=1">预约咨询</div>
                </div>
                <template v-if="tabIndex==0">
                    <div class="contact_content_c">
                        <img src="@/assets/common/qr.png" alt="">
                        <div>微信扫一扫，联系我们</div>
                    </div>
                    <div class="contact_content_b">
                        <div>
                            <span>联系人：阙经理</span>
                            <span>座机：023-47838701</span>
                        </div>
                        <div>
                            <span>手 机：13896067107</span>
                            <span>邮箱：quehongyan@xibushigu.com</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="pcpop-c">
                        <div class="box">
                            <el-form :model="reqData" ref="reqForm" :rules="rules">
                                <el-form-item prop="name">
                                    <div class="box-in">
                                        <input type="text" placeholder="请输入联系人名字" v-model="reqData.name">
                                    </div>
                                </el-form-item>
                                <el-form-item prop="enterprise">
                                    <div class="box-in">
                                        <input type="text" placeholder="请输入所属企业名字" v-model="reqData.enterprise">
                                    </div>
                                </el-form-item>
                                <el-form-item prop="phone">
                                    <div class="box-in">
                                        <input type="text" placeholder="请输入联系电话" v-model="reqData.phone">
                                    </div>
                                </el-form-item>
                                <el-form-item prop="email">
                                    <div class="box-in">
                                        <input type="text" placeholder="请输入联系邮箱" v-model="reqData.email">
                                    </div>
                                </el-form-item>

                            </el-form>
                        </div>
                        <div class="button" @click="postContact">
                            <div class="word"> 提交</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>


    </div>

    <!--    选择我们 e-->
</template>

<script>
    import {
        contact
    } from '@/api/contactus/contactus'

    export default {
        name: "zxhz",
        data() {
            return {
                show: false,
                showLc: false,
                tabIndex: 0,
                reqData: {
                    "email": "",//邮箱
                    "enterprise": "",//企业名称
                    "name": "",//姓名
                    "phone": ""//手机号
                },
                rules: {
                    phone: [
                        {required: true, message: "请输入手机号", trigger: "blur"},
                        // 这个只能验证手机号
                        // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
                        {pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "请输入正确手机号/电话号", trigger: "blur"}
                    ],
                    email: [
                        {required: true, message: "请输入邮箱", trigger: "blur"},
                        {
                            pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
                            message: "请输入正确的邮箱",
                            trigger: "blur"
                        }
                    ],
                    name: [
                        {required: true, message: "请输入姓名", trigger: "blur"}
                    ],
                    enterprise: [
                        {required: true, message: "请输入企业名称", trigger: "blur"}
                    ]
                }
            }
        },
        methods: {
            postContact() {
                this.$refs.reqForm.validate((valid) => {
                    if (valid) {
                        contact(this.reqData).then(res => {
                            console.log(res)
                            if (res.code == 200) {
                                this.$message.success("预约成功");
                                this.$refs.reqForm.resetFields()
                                this.show = false
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        this.$message.error('请输入正确的内容');
                        return false;
                    }
                });
                //
            }
        }
    }
</script>

<style scoped lang="scss">
    //选择我们 s
    .jc-choose {
        margin-top: 40px;
        width: 100%;
        height: 447px;
        background: #FBFBFB;
        .jc-choose-c {
            display: flex;
            @include page1200;
            .jc-choose-l {
                margin-right: 300px;
                @include flexCenter;
                flex-direction: column;
                > div {
                    @include flexCenter;
                    margin-bottom: 44px;
                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
                img {
                    width: 74px;
                    height: 74px;
                }
                span {
                    @include flexCenter;
                    display: inline-flex;
                    margin-left: 30px;
                    width: 125px;
                    height: 43px;
                    background: #F5F5F5;
                }
            }

            .jc-choose-r {
                display: block;
                position: relative;
                img {
                    width: 538px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 2;
                    width: 24px;
                    height: 228px;
                    background: #1320E8;
                    transform: translateX(100%);
                }
            }
        }
    }

    //选择我们 e

    //弹窗合作 s
    .contact_content {
        padding-top: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 520px;
        height: 538px;
        background-color: #fff;
    }

    .contact_content_t {
        display: flex;
        justify-content: center;
        color: #bbb;
        margin-bottom: 60px;

        div {
            &:hover {
                cursor: pointer;
            }
        }

        .select {
            color: #333333;
        }
    }

    .contact_content_c {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        border-bottom: 1px solid #F5F5F5;

        img {
            width: 200px;
            height: 200px;
        }

        div {
            margin-top: 15px;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }

    .contact_content_b {
        width: 100%;
        padding: 30px 50px 49px;

        div {
            margin-bottom: 15px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFang-SC-Light, PingFang-SC;
            font-weight: 300;
            color: #333333;
            line-height: 20px;
        }

        div:last-child {
            margin-bottom: 0;
        }
    }

    .pcpop-c {
        width: 520px;
        background: #FFFFFF;

        .fir_tie {
            display: flex;

            .titlec_a {
                width: 120px;
                height: 28px;
                font-size: 20px;
                margin-top: 30px;
                margin-left: 200px;
                font-weight: 500;
                color: #333333;
                line-height: 28px;
            }

            img {
                width: 25px;
                height: 25px;
                margin-top: 31px;
                margin-left: 125px;
            }
        }

        .box {
            width: 420px;
            height: 292px;
            display: flex;
            flex-wrap: wrap;
            //background-color: powderblue;
            margin: 30px auto 50px;

            .box-in {
                width: 420px;
                height: 58px;
                margin-bottom: 20px;

                input {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #E5E5E5;
                    padding-left: 20px;
                }
            }
        }

        .button {
            width: 420px;
            height: 58px;
            background: #002fdf;
            margin: 0 auto;

            .word {
                width: 36px;
                height: 25px;
                font-size: 18px;
                margin: 0 auto;
                padding-top: 17px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 25px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    ::v-deep .el-form-item {
        margin-bottom: 0;
    }

    ::v-deep .el-form-item__error {
        top: 22px;
        right: 20px;
        left: auto;
    }

    .contact_box {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 999;

        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .25);
        }

        .liuchengbox {
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            @include flexCenter;
        }
    }

    .contact_btn_box {
        position: absolute;
        flex-direction: column;
        right: 0;
        top: 100px;
        width: 48px;
        height: 134px;
        background: #13C228;
        border-radius: 1px;
        z-index: 98;
        display: flex;
        align-items: center;
        padding-top: 17px;

        &:hover {
            cursor: pointer;
        }

        img {
            margin-bottom: 11px;
            width: 16px;
        }

        div {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 19px;
        }
    }

    //弹窗合作 e
</style>