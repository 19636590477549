<template>
    <div class="head-content-box">
        <div class="imgBox" :style="{height:imgHeight}">
            <slot name="bgImg"></slot>
            <div class="text_box_b">
                <div class="text_box">
                    <slot name="title"></slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "HeadContent",
        props: {
            imgHeight: {
                default: "auto",
                type: String
            }
        }
    }
</script>

<style scoped lang="scss">
    .head-content-box {
        width: 100%;
        position: relative;

        .text-box {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 1200px;
            height: 100%;
            background-color: #000;

        }
    }

    .imgBox {
        position: relative;
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
        }

        .text_box_b {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .text_box {
                width: 1200px;
                height: 100%;
                margin: 0 auto;
                font-size: 50px;
                font-family: PingFang SC;
                letter-spacing: 2px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                margin-top: 35px;
            }
        }

    }

</style>
