<template>
    <div class="home">
        <NavBar></NavBar>
        <div class="container_box">
            <transition name="fade-transform" mode="out-in">
                <router-view/>
            </transition>
        </div>
        <Bottom></Bottom>
    </div>
</template>

<script>
    // @ is an alias to /src
    import NavBar from './components/NavBar.vue'
    import Bottom from "./components/Bottom";

    export default {
        name: 'Home',
        components: {
            NavBar, Bottom
        }
    }
</script>

<style lang="scss">
    @import "@/styles/mixin.scss";

    .container_box {
        width: 100%;
    }

    // global transition css

    /* fade */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.28s;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    /* fade-transform */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
        transition: all .5s;
    }

    .fade-transform-enter {
        opacity: 0;
        transform: translateX(-30px);
    }

    .fade-transform-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    /* breadcrumb transition */
    .breadcrumb-enter-active,
    .breadcrumb-leave-active {
        transition: all .5s;
    }

    .breadcrumb-enter,
    .breadcrumb-leave-active {
        opacity: 0;
        transform: translateX(20px);
    }

    .breadcrumb-move {
        transition: all .5s;
    }

    .breadcrumb-leave-active {
        position: absolute;
    }

</style>
