<template>
    <div class="dashboard">
        <nav class="hl_nav" :style="headerStyle">
            <div class="nav_img">
                <slot name="navLOGO">
                    <!-- <img class="nav_bg" src="../assets/logo.jpg"> -->
                </slot>
            </div>
            <ul class="nav_list">
                <div class="topLogo">
                    <img src="@/assets/common/logo-w.png" alt="" v-if="activeLogo==false">
                    <img src="@/assets/common/logo-blue.png" alt="" v-else>
                </div>

                <ul @mouseover="selectStyle ()" @mouseout="outStyle()">
                    <li class="xiala">
                        <a class="nav_head" :style="linkStyle" @click="goRouter('home')">首页</a>
                    </li>
                    <li class="xiala">
                        <a class="nav_head" :style="linkStyle" @click="goRouter('Gsituation')"
                        >关于我们</a
                        >
                        <ul class="offset_left offset_1">
                            <li><a @click="goRouter('Gsituation')">企业概况</a></li>
                            <li><a @click="goRouter('Culture')">企业文化</a></li>
                            <li><a @click="goRouter('Approve')">资质荣誉</a></li>
                            <li><a @click="goRouter('Promise')">我们的承诺</a></li>
                        </ul>
                    </li>
                    <li class="xiala">
                        <a class="nav_head" :style="linkStyle" @click="goRouter('inspection')"
                        >核心服务</a
                        >
                        <ul class="offset_left offset_2">
                            <li><a @click="goRouter('inspection')">检验检测</a></li>
                            <li><a @click="goRouter('certification')">认证认可</a></li>
                            <li>
                                <a class="noClick" @click="goRouter('Consult')">咨询服务</a>
                            </li>
                        </ul>
                    </li>
                    <li class="xiala">
                        <a class="nav_head" :style="linkStyle" @click="goRouter('informationCenter')">资讯中心</a>
                        <ul class="offset_left offset_3">
                            <li><a @click="goRouter('informationCenter',{index:1})">企业新闻</a></li>
                            <li><a @click="goRouter('informationCenter',{index:2})">法律法规</a></li>
                            <li><a @click="goRouter('informationCenter',{index:3})">行业动态</a></li>
                        </ul>
                    </li>
                    <li class="xiala">
                        <a class="nav_head" :style="linkStyle" @click="goRouter('clients')">联系我们</a>
                        <ul class="offset_left offset_4">
                            <li>
                                <a @click="goRouter('clients',{title:'客户中心'})">客户中心</a>
                            </li>
                            <li>
                                <a @click="goRouter('careers',{title:'招贤纳士'})">招贤纳士</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <li id="prep" :style="linkStyle" style="display: none"><span
                        style="opacity: 0.6;">ENG</span> <span class="login_btn" :style="btnStyle">登录</span></li>
            </ul>
            <div :class="[isShow===true?'show':'shade']"/>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "Dashboard",
        props: ["navstyle"],
        data() {
            return {
                headerStyle: {},
                linkStyle: {},
                btnStyle: {},
                loginBtnStyle: {},
                shadeStyle: {},
                activeLogo: true,
                navcope: false,
                isShow: false
            };
        },
        watch: {
            $route(val) {
                let name = val.name
                if (name == "home" || name == "article") {
                    this.setActiveColor(0)
                } else {
                    this.setActiveColor()
                }
            }
        },
        mounted() {
            window.addEventListener("scroll", this.windowScroll);
            if (this.$route.name == "home" || this.$route.name == "article") {
                this.setActiveColor(0)
            } else {
                this.setActiveColor()
            }
            console.log("mounted", this.headerStyle)
        },
        methods: {
            selectStyle() {
                this.isShow = true;
                this.setActiveColor()
            },
            outStyle() {
                this.isShow = false;
                if (this.$route.name == "home") {
                    this.setActiveColor(0)
                } else {
                    this.setActiveColor()
                }
            },
            goRouter(roter, query) {
                if (roter == "home" || roter == "article") {
                    this.setActiveColor(0)
                } else {
                    this.setActiveColor()
                }
                console.log("goRouter", this.headerStyle)
                this.$router.push({
                    name: roter,
                    query
                });
            },
            windowScroll() {
                const scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop
                if (this.$route.name == "home" || this.$route.name == "article") {
                    if (scrollTop > 100) {
                        this.headerStyle = {
                            backgroundColor: "#fff"
                        }
                        this.linkStyle = {
                            color: "#464646"
                        }
                        this.btnStyle = {
                            color: "#888",
                            borderColor: "#707070"
                        }
                        this.activeLogo = true
                    } else {
                        this.activeLogo = false
                        this.headerStyle = {
                            backgroundColor: "transparent"
                        }
                        this.linkStyle = {
                            color: "#fff"
                        }
                        this.btnStyle = {
                            color: "#fff",
                            borderColor: "#f5f5f5"
                        }
                        this.activeLogo = false
                    }
                }
            },
            /**
             *
             * @param {number} type
             */
            setActiveColor(type) {
                if (type === 0) {
                    this.headerStyle = {
                        backgroundColor: "transparent",
                        color: "#fff"
                    }
                    this.linkStyle = {
                        color: "#fff"
                    }
                    this.btnStyle = {
                        color: "#fbfbfb",
                        borderColor: "#fbfbfb"
                    }
                    this.activeLogo = false
                } else {
                    this.headerStyle = {
                        backgroundColor: "#fff"
                    }
                    this.linkStyle = {
                        color: "#464646"
                    }
                    this.btnStyle = {
                        color: "#888888",
                        borderColor: "#707070"
                    }
                    this.activeLogo = true
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/styles/mixin.scss";
    @import "@/styles/color.scss";

    .dashboard {
        width: 100%;

        .hl_nav {
            width: 100%;
            background-color: #fff;
            height: 70px;
            position: fixed;
            top: 0;
            left: 0;
            color: #000;
            z-index: 99;

            .nav_bg {
                width: 100%;
                height: 100%;
            }

            .echnical {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include WritingUp;

                :nth-child(1) {
                    font-size: 50px;
                }

                :nth-child(2) {
                    margin-top: 15px;
                    font-size: 22px;
                }

                .solution {
                    display: flex;
                    justify-content: flex-start;

                    .solu_first {
                        margin-right: 50px;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        margin-top: 40px;
                        padding: 20px 50px;
                        background: rgba($color: #fff, $alpha: 0.15);

                        span {
                            margin: 0;
                            margin-left: 30px;
                            font-size: 20px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            line-height: 28px;
                        }
                    }
                }
            }

            .solution {
                display: flex;
                justify-content: flex-start;

                .solu_first {
                    margin-right: 50px;
                }

                div {
                    display: flex;
                    align-items: center;
                    margin-top: 40px;
                    padding: 20px 50px;
                    background: rgba($color: #555, $alpha: 0.15);

                    span {
                        margin: 0;
                        margin-left: 30px;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #555;
                        line-height: 28px;
                    }
                }
            }
        }

        .hl_nav a {
            display: block;
            text-decoration: none;
        }

        .hl_nav .shade {
            -webkit-animation: a_bg_hover_hide 0.15s ease-in-out both;
            -webkit-transform-origin: 50% 0%;
            z-index: 999;
        }

        .hl_nav .nav_list {
            display: flex;
            width: 1200px;
            margin: 0 auto;
            z-index: 10;
            list-style: none;
            height: 70px;
        }

        .hl_nav .nav_list > ul {
            list-style: none;
            margin-left: 150px;
            width: 700px;
            padding: 0;
            display: flex;
            justify-content: center;
        }

        .hl_nav .nav_list > ul > li {
            padding: 0;
            float: left;
            margin: 0 24px;
            text-align: center;
            height: 70px;

            @include borderShade;
        }

        .xiala {
            position: relative;
            // background-color: red;
        }

        .xiala:hover + .active {
        }

        .nav_head:hover {
            cursor: pointer;
        }

        .hl_nav .nav_head {
            height: 60px;
            line-height: 70px;
            color: #555;
            font-size: 20px;
            font-weight: 500;
            position: relative;
            white-space: nowrap;
            z-index: 3;
            @include borderShade;

            &:after {
                content: "";
                position: absolute;
                top: 55px;
                width: 0;
                height: 3px;
                background: #002fdf;
                left: 50%;
                transform: translateX(-50%);
                @include linear;
            }
        }

        .hl_nav #prep,
        #Internet {
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            cursor: pointer;
        }

        .hl_nav #prep {
            margin-right: 0;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        // .prep_show{
        //   color: #efefef;
        // }
        .hl_nav #Internet {
            width: 150px;
            margin-right: 0;
        }

        .hl_nav #Internet #Inter_net {
            @include borderShade;
            display: block;
            width: 150px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            opacity: 0.75;
            border: 1px solid #ffffff;
        }

        .hl_nav #Internet #Inter_net:hover {
            width: 150px;
            height: 40px;
            background: #002fdf;
            border: 1px solid #002fdf;
            color: #ffffff;
        }

        .hl_nav .nav_list > ul > li > ul {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            padding: 0;
            margin: 0;
            padding-top: 70px;
            line-height: 58px;
            list-style: none;
            border: chartreuse;
            visibility: hidden;
            display: flex;
            flex-direction: column;
            width: 160px;
            left: 50%;
            margin-left: -80px;
            text-align: center;
            z-index: 2;
            -webkit-animation: a_bg_hover_show 0.15s ease-in-out both;
        }

        .hl_nav .nav_list > ul > li {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }

        .hl_nav .nav_list {
            .active {
                width: 100px;
                height: auto;
                margin: 0;
                cursor: pointer;
                @include borderShade;
            }
        }

        .hl_nav .nav_list:hover {
            // border-bottom: 1px solid #eee;

            #Inter_net {
                border: 1px solid #eee;
            }
        }

        .nav_list > ul > li:hover {
            position: relative;

            > ul {
                -webkit-animation: a_bg_hover_show 0.15s ease-in-out both;
            }

            .nav_head {
                color: #002fdf;

                &:after {
                    content: "";
                    position: absolute;
                    top: 55px;
                    width: 90%;
                    height: 3px;
                    background: #002fdf;
                    left: 50%;
                    transform: translateX(-50%);
                    @include linear;
                }
            }
        }

        .hl_nav .nav_list > ul > li:hover > ul {
            visibility: inherit;
        }

        .hl_nav .nav_list {
            padding: 0;
            box-sizing: border-box;
        }

        .hl_nav .nav_list > ul > li > ul > li {
            float: left;
        }

        .hl_nav .nav_list > ul > li > ul > li > a {
            width: 160px;
            height: 56px;
            text-decoration: none;
            font-size: 16px;
            font-weight: 300;
            display: block;
            color: #606060;
        }

        .hl_nav .nav_list > ul > li > ul > li > a:hover {
            cursor: pointer;
            position: relative;
            font-weight: 600;
        }

        //.hl_nav .nav_list:hover {
        //    height: 70px;
        //    background: #fff;
        //    color: #555555;
        //
        //    .nav_head {
        //        color: #555555;
        //    }
        //}
    }

    /* 所有设备、宽度必须大于800或者竖屏的时候，true */
    @media all and (min-width: 1921px), (orientation: portrait) {
        .hl_nav {
            width: 1920px;
            margin: 0 auto;
        }

        .nav_img {
            img {
                margin: 0 50%;
                transform: translate(-50%, 0%);
            }
        }
    }

    .noClick {
        @include noClick;
    }

    .topLogo {
        flex-shrink: 0;
        width: auto;
        width: 140px;
        height: 70px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 9;

        img {
            width: 100%;
        }
    }

    .login_btn {
        margin-left: 50px;
        border: 0.5px solid #888;
        width: 54px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #fbfbfb;

        &:hover {
            background-color: #1320e8;
            border-color: #1320e8 !important;
            color: #fff !important;
        }
    }

    .show {
        display: block !important;
        width: 100%;
        z-index: 1;
        height: 330px;
        background: rgba(255, 255, 255, 1);
        position: fixed;
        top: 0px;
        left: 0px;
        -webkit-animation: a_bg_hover_show 0.15s ease-in-out both;
        -webkit-transform-origin: 50% 0%;
    }

    @keyframes a_menu_item_show {
        0% {
            -webkit-transform: translate(0px, -30px);
            opacity: 0;
        }

        100% {
            -webkit-transform: translate(0px, 0px);
            opacity: 1;
        }
    }

    @keyframes a_menu_item_hide {
        0% {
            -webkit-transform: translate(0px, 0px);
            opacity: 1;
        }

        100% {
            -webkit-transform: translate(0px, -30px);
            opacity: 0;
        }
    }

    @keyframes a_bg_hover_show {
        0% {
            -webkit-transform: scale(1, 0.5);
            opacity: 0;
        }

        100% {
            -webkit-transform: scale(1, 1);
            opacity: 1;
        }
    }

    @keyframes a_bg_hover_hide {
        0% {
            -webkit-transform: scale(1, 1);
            opacity: 1;
        }

        100% {
            -webkit-transform: scale(1, 0.5);
            opacity: 0;
        }
    }

    /* 所有设备、宽度必须大于800或者竖屏的时候，true */
    @media all and (min-width: 1921px), (orientation: portrait) {
        .hl_nav {
            width: 1920px;
        }
    }

    /* 所有设备、宽度必须大于800或者竖屏的时候，true */
    @media all and (max-width: 1200px), (orientation: portrait) {
        .hl_nav {
            width: 1920px;
        }
    }
</style>
