<template>
    <div class="gkarrow" :style="{color:color}">
        <i class="el-icon-back" @click="lFn" :style="{borderColor:borderColor}"></i>
        <i class="el-icon-right" @click="rFn" :style="{borderColor:borderColor}"></i>
    </div>
</template>

<script>
    export default {
        name: "GkArrow",
        props: {
            //箭头颜色
            color: {
                default: '#606060',
                type: String
            },
            //虚线边框颜色
            borderColor: ""
        },
        methods: {
            rFn() {
                this.$emit("rFn")
            },
            lFn() {
                this.$emit("lFn")
            }
        }
    }
</script>

<style scoped lang="scss">
    .gkarrow {
        display: inline-flex;
        color: #606060;
        i {
            width: 86px;
            height: 86px;
            border: 1px dotted rgba(#606060, 0.26);
            border-radius: 50%;
            @include flexCenter;

            &:last-child {
                transform: translateX(-16px);
            }
        }

    }
</style>