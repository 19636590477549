<template>
    <div class="bottom-box">
        <div class="bottom-c">
            <div class="bottom-c-l">
                <img src="../../../assets/common/logo-w.png" alt="">
            </div>
            <div class="bottom-c-r">
                <div class="bottom-qr" :style="{opacity:showQr?1:0}">
                    <img src="@/assets/common/caht-qr.jpg" alt="" style="width: 100%;height: 100%;">
                </div>
                <div class="bottom-lx" :style="{opacity:showLX?1:0}">
                    电话:023-85579776
                </div>
                <div>
                    <div class="cont">
                        <span>联系方式</span>
                        <div @mouseenter="showLX=true" @mouseleave="showLX=false">
                            <img src="@/assets/common/telephone (1)@2x.png" alt="">
                        </div>
                        <div class="qrbox" @mouseenter="showQr=true" @mouseleave="showQr=false">
                            <img src="@/assets/common/组 175@2x.png" alt="">
                        </div>
                        <div>
                            <img src="@/assets/common/微博@2x.png" alt="">
                        </div>
                    </div>
                    <div class="bottom-nav">
                        <div @click="goRouter('Gsituation')">关于我们</div>
                        <div @click="goRouter('inspection')">核心服务</div>
                        <div @click="goRouter('Promise')">我们的承诺</div>
                        <div @click="goRouter('Gsituation')">企业概况</div>
                        <div @click="goRouter('inspection')">检验检测</div>
                        <div @click="goRouter('Declaration',{title:'服务承诺'})">服务承诺</div>
                        <div @click="goRouter('Culture')">企业文化</div>
                        <div @click="goRouter('certification')">认证认可</div>
                        <div @click="goRouter('Declaration',{title:'保密承诺'})">保密承诺</div>
                        <div @click="goRouter('Approve')">资质荣誉</div>
                        <div @click="goRouter('Consult')">咨询服务</div>
                        <div @click="goRouter('Declaration',{title:'公正性声明'})">公正性声明</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="bottom-text">
            <a> 渝ICP备2021005039号</a>
            <a>渝公安网备50011602500628号</a>
            <div>
                <span @click="goRouter('others',{title:'法律声明'})">法律声明</span>｜
                <span @click="goRouter('others',{title:'隐私条款'})">隐私条款</span>｜
                <span @click="goRouter('others',{title:'版权声明'})">版权声明</span>
            </div>
            <a>Copyright © 2021 Gukotest. All Rights Reserved. 重庆国科 版权所有</a>
        </div>
        <div class="xie-line-box">
            <div class="xie-line"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bottom",
        data() {
            return {
                showQr: false,
                showLX: false
            }
        },
        methods: {
            goRouter(roter, query) {
                this.$router.push({
                    name: roter,
                    query
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .bottom-box {
        position: relative;
        width: 100%;
        height: 500px;
        background: #172244;

        .bottom-c {
            //@include page1200;
            height: 400px;
            display: flex;
            flex-direction: row;

            .bottom-c-l {
                width: 50%;
                padding-right: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 236px;
                    height: auto;
                }
            }

            .bottom-c-r {
                padding-left: 50px;
                position: relative;
                width: 50%;
                display: flex;
                align-items: center;
                color: #fff;

                .cont {
                    position: relative;
                    padding-bottom: 30px;
                    max-width: 350px;
                    display: flex;
                    align-items: center;

                    &:after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #f5f5f5;
                        left: 0;
                        bottom: 0;
                        transform: scaleY(.2);
                    }

                    span {
                        margin-right: 26px;
                    }

                    div {
                        margin-right: 10px;
                        border-radius: 50%;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(#fff, .3);
                        padding: 14px;

                        img {
                            width: 14px;
                            opacity: .6;
                        }

                        &:hover {
                            cursor: pointer;
                            background-color: #1320E8;

                            img {
                                opacity: 1;
                            }
                        }

                    }

                }

            }
        }

        .bottom-text {
            overflow: hidden;
            font-size: 14px;
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 100%;
            height: 180px;
            color: #fbfbfb;
            z-index: 2;
            display: flex;
            flex-direction: row;
            transform: translateX(-50%);
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 20px;
            a {
                margin-right: 30px;

                &:hover {
                    cursor: pointer;
                    color: #99a9bf;
                }
            }

            div {
                margin-right: 30px;

                span:hover {
                    cursor: pointer;
                    color: #99a9bf;
                }
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 200%;
                height: 200px;
                transform: rotate(1.4deg) translate(-20%, 120px);
                background-color: #1320E8;
            }
        }

        .bottom-nav {
            display: flex;
            flex-wrap: wrap;
            max-width: 600px;
            margin-top: 40px;

            div {
                &:hover {
                    cursor: pointer;
                    color: #999;
                }

                margin-right: 100px;
                margin-bottom: 20px;
            }
        }

    }

    .bottom-qr {
        background-color: gray;
        position: absolute;
        top: -100px;
        left: 110px;
        width: 160px;
        height: 160px;
        opacity: 0;
        transition: opacity .3s ease;
    }

    .bottom-lx {
        background-color: #fff;
        position: absolute;
        top: 10px;
        left: 60px;
        width: 180px;
        height: 40px;
        opacity: 0;
        color: #333;
        transition: opacity .3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
