import request from '@/axios/index.js'

// 获取联系方式
export function getContacts() {
    return request({
        url: '/portal/porContact/list',
        method: 'GET'
    })
}

// 预约咨询
export function contact(data) {
    return request({
        url: '/portal/consulting',
        method: 'POST',
        data
    })
}

