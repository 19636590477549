import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

//vue-meta-info
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

//数字跳动
import countTo from 'vue-count-to';

Vue.component("countTo", countTo)

import GkCollapse from "./components/GkCollapse/GkCollapse";

Vue.component("GkCollapse", GkCollapse)

//公共头图
import HeadContent from "./views/layout/components/HeadContent";

Vue.component("HeadContent", HeadContent)

//切换箭头组合
import GkArrow from "./components/GkArrow/GkArrow";

Vue.component("GkArrow", GkArrow)

//切换箭头组合
import zxhz from "./components/zxhz/zxhz";

Vue.component("zxhz", zxhz)

//切换箭头组合
import contact from "./components/contact/contact";

Vue.component("contact", contact)


Vue.prototype.isEmpty = (a) => {
    if (a === "") return true; //检验空字符串
    if (a === "null") return true; //检验字符串类型的null
    if (a === "undefined") return true; //检验字符串类型的 undefined
    if (!a && a !== 0 && a !== "") return true; //检验 undefined 和 null
    if (Array.prototype.isPrototypeOf(a) && a.length === 0) return true; //检验空数组
    if (Object.prototype.isPrototypeOf(a) && Object.keys(a).length === 0) return true;  //检验空对象
    return false;
}

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});

Vue.config.productionTip = false


Vue.prototype.goRouter = (roter, query) => {
    router.push({
        name: roter,
        query
    });
}

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    },
}).$mount('#app')
