<template>
    <div id="app">
        <!--   <div id="nav">
          <router-link to="/">Home</router-link> |
          <router-link to="/about">About</router-link>
        </div> -->
        <router-view/>
    </div>
</template>
<script>
    export default {
        name: 'App',
        metaInfo: {
            title: '重庆国科食品检测', // set a title
            meta: [{ // set meta
                name: 'keyWords',
                content: '重庆国科食品检测有限公司,食品检测,检测培训，培训，食品认证，体系认证，标准制定，咨询培训，食品科研，食品研究，标准制定，计量服务，重庆国科,检测，国科，重庆，西南'
            }, {
                name: 'description',
                content: '重庆国科食品检测有限公司是一家专业的第三方检验认证机构，集科研、培训、服务于一体的高科技服务型企业。提供检验检测、认证、安全评价、计量服务、标准制定、咨询培训等服务。为企业提供全链条、全方位、全过程“一站式”综合服务。'
            }]
        }
    }
</script>
<style lang="scss">
    * {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        display: block;
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }

    .page {
        width: 100%;
        position: relative;
        overflow-x: hidden;
    }

    #app {
        // font-family: Avenir, Helvetica, Arial, sans-serif;
        font-family: PingFang SC;
    }

    .container {
        margin: 0 auto 100px;
        max-width: 1200px;
    }

    .btn-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .com-more-btn {
        margin-top: 30px;
        color: #4954F7;
        border: 1px solid #465AEE;
        padding: 6px 14px;
        font-size: 12px;
        letter-spacing: 2px;

    }

    .com-more-btn:hover {
        cursor: pointer;
        color: #fff;
        background-color: #1320E8;
        border-color: #1320E8;
    }

    /* 所有设备、宽度必须大于800或者竖屏的时候，true */
    @media all and (min-width: 1921px), (orientation: portrait) {
        body {
            width: 1920px;
        }
    }

    /* 所有设备、宽度必须大于800或者竖屏的时候，true */
    @media all and (max-width: 1200px), (orientation: portrait) {
        body {
            width: 1920px;
        }
    }

</style>
