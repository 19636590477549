/*
 * @Author: your name
 * @Date: 2021-05-25 10:14:07
 * @LastEditTime: 2021-07-19 10:10:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xbsg-website\src\utils\request.js
 */
import axios from 'axios'
import {Message} from 'element-ui'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // config.headers['Authorization'] = '6KW/6YOo6aOf6LC356S+5Yy66L+Q6JCl566h55CG5bmz5Y+w'
        // do something before request is sent

        // if (store.getters.token) {
        //     // let each request carry token
        //     // ['X-Token'] is a custom headers key
        //     // please modify it according to the actual situation
        //     // config.headers['X-Token'] = getToken()
        // }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // 无效token是2002
        // token过期是2001
        // code 1请求出错
        // if (res.code !== 0 && res.code !== 200) {
        //     if (res.code === 2002 || res.code === 2001) {
        //         return
        //     }
        //     if (res.code === 2) {
        //         return
        //     }
        //     Message({
        //         message: res.message || res.msg,
        //         type: 'error',
        //         duration: 1 * 1000
        //     })
        //     return Promise.reject(new Error(res.message || 'Error'))
        // } else {
        //     return res
        // }

        //有个接口没返回码，暂时改成直接返回res
        return res
    },
    error => {
        console.log('err' + error)
        return Promise.reject(error)
    }
)

export default service
